@font-face {
    font-family: "Gilroy-Bold-Italic";
    src: url("fonts/Gilroy-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy";
    src: url("fonts/Gilroy-100.woff") format("woff"), url("fonts/Gilroy-100.woff2") format("woff2");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url("fonts/Gilroy-200.woff") format("woff"), url("fonts/Gilroy-200.woff2") format("woff2");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url("fonts/Gilroy-300.woff") format("woff"), url("fonts/Gilroy-300.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url("fonts/Gilroy-400.woff") format("woff"), url("fonts/Gilroy-400.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url("fonts/Gilroy-500.woff") format("woff"), url("fonts/Gilroy-500.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url("fonts/Gilroy-500-italic.woff") format("woff"), url("fonts/Gilroy-500-italic.woff2") format("woff2");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Gilroy";
    src: url("fonts/Gilroy-600.woff") format("woff"), url("fonts/Gilroy-600.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url("fonts/Gilroy-700.woff") format("woff"), url("fonts/Gilroy-700.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy-Bold";
    src: url("fonts/Gilroy-700.woff") format("woff"), url("fonts/Gilroy-700.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url("fonts/Gilroy-800.woff") format("woff"), url("fonts/Gilroy-800.woff2") format("woff2");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url("fonts/Gilroy-900.woff") format("woff"), url("fonts/Gilroy-900.woff2") format("woff2");
    font-weight: 900;
    font-style: normal;
}
