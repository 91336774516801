@import "functions.scss";
 
@mixin elipsis($lines) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
}

@mixin fs-button-skew($border, $background, $color) {
    border: $border;
    background-color: $background;
    color: $color;
    position: relative;
    box-sizing: border-box;
    outline: none;
    min-width: 250px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: -2px;
        width: 20px;
        height: 100%;
        border: $border;
        background-color: $background;
    }
}

@mixin large-fs-button-skew($border, $background, $color) {
    border: $border;
    background-color: $background;
    color: $color;
    position: relative;
    box-sizing: border-box;
    outline: none;
    min-width: 250px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: -2px;
        width: 60px;
        height: 100%;
        border: $border;
        background-color: $background;
    }
}

//=========================================================

@mixin fs-button-skew-right($border, $background, $color) {
    @include fs-button-skew($border, $background, $color);
    padding: 10.5px 30px 10.5px 40px;
    border-right: none;

    &:after {
        right: -10px;
        border-left: none;
    }
}

@mixin fs-button-skew-left($border, $background, $color) {
    @include fs-button-skew($border, $background, $color);
    padding: 10.5px 40px 10.5px 30px;
    border-left: none;

    &:after {
        left: -10px;
        border-right: none;
    }
}

//=========================================================

@mixin large-fs-button-skew-right($border, $background, $color) {
    @include large-fs-button-skew($border, $background, $color);
    padding: 10.5px 30px 10.5px 40px;
    border-right: none;

    &:after {
        right: -30px;
        border-left: none;
    }
}

@mixin large-fs-button-skew-left($border, $background, $color) {
    @include large-fs-button-skew($border, $background, $color);
    padding: 10.5px 40px 10.5px 30px;
    border-left: none;

    &:after {
        left: -30px;
        border-right: none;
    }
}

//=========================================================

@mixin fs-button-skew-right-from-top($border, $background, $color) {
    @include fs-button-skew-right($border, $background, $color);

    &:after {
        transform: skew(20deg);
    }
}

@mixin fs-button-skew-right-from-bottom($border, $background, $color) {
    @include fs-button-skew-right($border, $background, $color);

    &:after {
        transform: skew(-20deg);
    }
}

@mixin fs-button-skew-left-from-top($border, $background, $color) {
    @include fs-button-skew-left($border, $background, $color);

    &:after {
        transform: skew(-20deg);
    }
}

@mixin fs-button-skew-left-from-bottom($border, $background, $color) {
    @include fs-button-skew-left($border, $background, $color);

    &:after {
        transform: skew(20deg);
    }
}

//=========================================================

@mixin large-fs-button-skew-right-from-top($border, $background, $color) {
    @include large-fs-button-skew-right($border, $background, $color);

    &:after {
        transform: skew(20deg);
    }
}

@mixin large-fs-button-skew-right-from-bottom($border, $background, $color) {
    @include large-fs-button-skew-right($border, $background, $color);

    &:after {
        transform: skew(-20deg);
    }
}

@mixin large-fs-button-skew-left-from-top($border, $background, $color) {
    @include large-fs-button-skew-left($border, $background, $color);

    &:after {
        transform: skew(-20deg);
    }
}

@mixin large-fs-button-skew-left-from-bottom($border, $background, $color) {
    @include large-fs-button-skew-left($border, $background, $color);

    &:after {
        transform: skew(20deg);
    }
}

//=========================================================

@mixin large-fs-button-skew-right-from-top-shadowed($border, $background, $color) {
    @include large-fs-button-skew-right($border, $background, $color);
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);

    &:after {
        transform: skew(20deg);
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
    }

    &:before {
        content: "";
        display: block;
        transform: skew(-20deg);
        background-color: $background;
        width: 60px;
        height: 100%;
        position: absolute;
        top: -2px;
        border: $border;
        right: 20px;
        z-index: 5;
    }
}

@mixin large-fs-button-skew-right-from-bottom-shadowed($border, $background, $color) {
    @include large-fs-button-skew-right($border, $background, $color);
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);

    &:after {
        transform: skew(-20deg);
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
    }

    &:before {
        content: "";
        display: block;
        transform: skew(-20deg);
        background-color: $background;
        width: 60px;
        height: 100%;
        position: absolute;
        top: -2px;
        border: $border;
        right: 20px;
        z-index: 5;
    }
}

@mixin large-fs-button-skew-left-from-top-shadowed($border, $background, $color) {
    @include large-fs-button-skew-left($border, $background, $color);
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);

    &:after {
        transform: skew(-20deg);
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
    }

    &:before {
        content: "";
        display: block;
        transform: skew(-20deg);
        background-color: $background;
        width: 60px;
        height: 100%;
        position: absolute;
        top: -2px;
        border: $border;
        left: 20px;
        z-index: 5;
    }
}

@mixin large-fs-button-skew-left-from-bottom-shadowed($border, $background, $color) {
    @include large-fs-button-skew-left($border, $background, $color);
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);

    &:after {
        transform: skew(20deg);
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
    }

    &:before {
        content: "";
        display: block;
        transform: skew(-20deg);
        background-color: $background;
        width: 60px;
        height: 100%;
        position: absolute;
        top: -2px;
        border: $border;
        left: 20px;
        z-index: 5;
    }
}

//=========================================================

@mixin fs-skew-button-border($border) {
    ::ng-deep {
        --fs-border-width: #{get-border-width($border)};
        --fs-border-style: #{get-border-style($border)};
        --fs-border-color: #{get-border-color($border)};
    }
}
@mixin fs-skew-button-background-color($backgroundColor) {
    ::ng-deep {
        --fs-background-color: #{$backgroundColor};
    }
}
@mixin fs-skew-button-color($color) {
    ::ng-deep {
        --fs-color: #{$color};
    }
}
@mixin fs-skew-button-text-transform($textTransform) {
    ::ng-deep {
        --fs-text-transform: #{$textTransform};
    }
}
@mixin fs-skew-button-box-shadow($boxShadow) {
    ::ng-deep {
        --fs-box-shadow: #{$boxShadow};
    }
}

@mixin fs-skew-button-font-family($fontFamily) {
    ::ng-deep {
        --fs-skew-font-family: #{$fontFamily};
    }
}

@mixin fs-skew-button-font-size($fontSize) {
    ::ng-deep {
        --fs-skew-font-size: #{$fontSize};
    }
}

@mixin fs-skew-button-skew($skew) {
    ::ng-deep {
        --fs-skew: #{$skew};
    }
}
