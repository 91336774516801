@import "colors.scss";
@import "mixins.scss";

html {
    scroll-behavior: smooth;
    font-family: "Gilroy";
    font-size: 62.5%;

    * {
        font-family: "Gilroy" !important;
    }
}

body {
    margin: 0 auto;
    padding: 0;
    width: 100%;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }

    h1 {
        // font-size: 15rem;
        // line-height: 15rem;
        font-size: 12rem;
        line-height: 12rem;
        font-weight: 700;
    }

    h2 {
        font-size: 15rem;
        line-height: 15rem;
        font-weight: 900;
        margin: 0 0 -27px -8px;
    }

    h3 {
        font-size: 20rem;
        line-height: 15rem;
        font-weight: 900;
        margin: 0 0 0 -10px;
    }

    h4 {
        font-size: 10rem;
        line-height: 10rem;
        font-weight: 900;
    }

    p {
        &.hero-description {
            color: $color-white;
            margin: 45px 0 0 0;
            font-weight: 400;
            font-size: 3rem;
            line-height: 4.8rem;
        }
    }

    &.fixed {
        overflow: hidden;
    }

    &.calendly-background {
        background-color: #fbfcfd;
    }

    .fs-section {
        position: relative;
        .background-mask {
            position: absolute;
            width: 100%;
            height: 100%;
            .background {
                position: relative;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                overflow: hidden;
                .guides {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    pointer-events: none;
                    .guides-container {
                        display: grid;
                        grid: 1fr / repeat(4, 1fr);
                        position: relative;
                        max-width: 1280px;
                        height: 100%;
                        margin: 0 auto;
                        .guide {
                            width: 1px;
                            background: -webkit-gradient(
                                linear,
                                left top,
                                left bottom,
                                from($grid-dash-color),
                                color-stop(50%, $grid-dash-color),
                                color-stop(50%, transparent),
                                to(transparent)
                            );
                            background: linear-gradient(
                                180deg,
                                $grid-dash-color,
                                $grid-dash-color 50%,
                                transparent 0,
                                transparent
                            );
                            background-size: 1px 8px;
                            &:first-of-type {
                                background-color: $grid-dash-color;
                            }
                            &:last-of-type {
                                background-color: $grid-dash-color;
                                position: absolute;
                                top: 0;
                                right: 0;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
        .container {
            position: relative;
            z-index: 1;
            display: -webkit-box;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            .layout {
                width: 100%;
                max-width: 1280px;
                margin: 0 auto;
                padding: 50px 0;
                box-sizing: border-box;
            }
        }
    }
    .grid {
        display: grid;
        grid: auto/repeat(4, 1fr);
        justify-items: center;
        -webkit-box-align: center;
        align-items: center;
        row-gap: 60px;
    }
    .row-layout {
        display: grid;
        grid: auto/minmax(0, 1fr);
        row-gap: 60px;
        .column-layout {
            display: grid;
            -webkit-box-align: start;
            align-items: flex-start;
            &[data-columns="2,2"] {
                grid-template-columns: repeat(2, 1fr);
            }
            &[data-columns="4,4"] {
                grid: auto/repeat(4, 1fr);
            }
            &[data-columns="3,3"] {
                grid: auto/repeat(3, 1fr);
            }
            &[data-columns="1,3"] {
                grid-template-columns: 25% 75%;
            }
            &[data-columns="1,2"] {
                grid-template-columns: 33.33% 66.67%;
            }
            &[data-columns="1,1,1"] {
                grid-template-columns: 33.33% 33.33% 33.33%;
            }
            &[data-columns="1,2,1"] {
                grid-template-columns: 25% 50% 25%;
            }
        }
    }
    .fs-button {
        display: block;
        outline: none;
        width: 100%;
        cursor: pointer;
        font-weight: bold;
        letter-spacing: 0.02em;
        background-color: $color-yellow;
        border: 6px solid $color-yellow;
        border-radius: 76px;
        padding: 12px 22px 12px 22px;
        margin: 100px 0px;
        color: $color-black;
    }
    .fs-cta-button {
        display: block;
        outline: none;
        width: 100%;
        cursor: pointer;
        font-weight: bold;
        letter-spacing: 0.02em;
        background-color: $color-yellow;
        border: 6px solid $color-yellow;
        color: $color-black;
        border-radius: 76px;
        padding: 12px 22px 12px 22px;
        margin: 100px 0px;
        box-sizing: border-box;
        text-align: center;
        text-decoration: none;
        h3 {
            color: $color-black !important;
        }
    }
    .fs-check-box {
        display: block;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        font-size: 2.2rem;
        user-select: none;
        min-height: 23px;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            &:checked {
                ~ .checkmark {
                    background-color: $color-2;
                    border: 0;
                }
                ~ .checkmark:after {
                    display: block;
                }
            }
            & ~ .checkmark {
                position: absolute;
                top: 0px;
                left: 0;
                height: 40px;
                width: 40px;
                border: 3px solid $color-white;
                box-sizing: border-box;
                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                    // top: 3px;
                    // left: 3px;
                    margin-top: 10px;
                    margin-left: 10px;
                    width: 20px;
                    height: 20px;
                    background-image: url(assets/check-blue.svg);
                }
            }
        }
        input[type="radio"] {
            & ~ .checkmark {
                border-radius: 50%;
                &:after {
                    border-radius: 50%;
                }
            }
        }
        input[type="checkbox"] {
            & ~ .checkmark {
                border-radius: 5px;
                &:after {
                    // border-radius: 50%;
                }
            }
        }
        &:hover {
            input[type="radio"] ~ .checkmark {
                background-color: $color-white;
            }
        }
    }

    .fs-radio-box {
        display: block;
        position: relative;
        padding-left: 39px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 1.8rem;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;

            &:checked {
                & ~ .checkmark {
                    &:after {
                        display: block;
                        content: "";
                    }
                }
            }
        }

        .checkmark {
            display: block;
            position: absolute;
            top: -1px;
            left: 0;
            height: 25px;
            width: 25px;
            border-radius: 50%;
            border: 3px solid $color-black;
            box-sizing: border-box;

            &:after {
                top: 3px;
                left: 0;
                width: 13px;
                height: 13px;
                border-radius: 50%;
                background: $color-black;
                text-align: center;
                right: 0;
                margin: auto;
                position: absolute;
            }
        }

        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }

    section {
        width: 100%;

        .container {
            width: 90%;
            max-width: 1280px;
            margin: 0 auto;
            // padding: 100px 0 0 0; //TODO:xxxx
            box-sizing: border-box;
        }
    }
}

.fs-c-check {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 2.2rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.black {
        input {
            &:checked ~ .checkmark {
                background-color: $color-black; //inner background
            }
        }

        .checkmark {
            background-color: transparent; //not selected backgroundbackground-color: blue;
            border: 2px solid $color-black;

            &:after {
                border: solid $color-13; //check-color
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }

    &.disabled {
        .label {
            opacity: 0.4;
        }
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &[disabled] {
            ~ .checkmark {
                opacity: 0.4;
            }
        }

        &:checked ~ .checkmark {
            background-color: $color-13; //inner background

            &:after {
                display: block;
            }
        }
    }

    .label {
        display: inline-block;

        font-size: 1.2rem;
        font-weight: 500;
        vertical-align: top;
        margin: 3px 0 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: $color-white; //not selected backgroundbackground-color: blue;
        border: 2px solid $color-13; //not selected border
        box-sizing: border-box;

        &:after {
            left: 7px;
            top: 3px;
            width: 5px;
            height: 10px;
            border: solid $color-black; //check-color
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);

            content: "";
            position: absolute;
            display: none;
        }
    }
}

.input-with-placeholder {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;

    label {
        position: absolute;
        color: $color-black;
        z-index: -1;
        text-transform: uppercase;

        font-size: 1.6rem;
        font-weight: 700;

        span {
            text-transform: capitalize;

            font-size: 1.6rem;
            font-weight: 500;
        }
    }

    input[type="text"] {
        min-height: 30px;
        height: 100%;
        border: none;
        outline: none;
        background-color: transparent;
        border-bottom: 1px solid $color-black;
        padding: 0 7px;
        box-sizing: border-box;
    }
}

@media (min-width: 1281px) and (max-width: 1780px) {
    body {
        h1 {
            font-size: 12rem;
            line-height: 12rem;
        }
    }
}

@media (min-width: 901px) and (max-width: 1280px) {
    body {
        h1 {
            font-size: 12rem;
            line-height: 12rem;
        }

        h3 {
            font-size: 15rem;
            line-height: 12rem;
            margin: -64px 0 0 -5px;
        }
    }
}

@media (min-width: 601px) and (max-width: 900px) {
    body {
        h1 {
            font-size: 8rem;
            line-height: 9rem;
        }

        h3 {
            font-size: 12rem;
            line-height: 12rem;
            margin: -75px 0 0 -7px;
        }
    }
}

@media (min-width: 320px) and (max-width: 600px) {
    body {
        h1 {
            font-size: 5rem;
            line-height: 6rem;
        }

        h2 {
            font-size: 8rem;
            line-height: 8rem;
            margin: 0 0 -12px -3px;
        }

        h3 {
            font-size: 10rem;
            line-height: 10rem;
            margin: -69px 0 0 -5px;
        }
    }
}

@media (min-width: 320px) and (max-width: 900px) {
    body {
        // h1 {
        //     font-size: 10rem;
        //     line-height: 110px;
        // }

        h2 {
            // font-size: 8rem;
            // line-height: 100px;
            // margin: 0 0 -22px -3px;
        }

        h3 {
            // font-size: 10rem;
            // line-height: 100px;
            // margin: -69px 0 0 -5px;
        }

        p {
            &.hero-description {
                margin: 45px 0 0 0;
                font-size: 2.4rem;
                line-height: 3.2rem;
            }
        }
    }
}

@media (max-height: 900px) and (min-width: 800px) {
    body {
        h1 {
            font-size: 8rem;
            line-height: 8rem;
        }

        p {
            &.hero-description {
                margin-top: 20px;
                margin-bottom: -20px;
                font-size: 2.5rem;
                line-height: 4rem;
            }
        }
    }
}

@media (min-height: 901px) and (max-height: 1100px) and (min-width: 800px) {
    body {
        h1 {
            font-size: 11rem;
            line-height: 11rem;
        }
    }
}

.form-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.full {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    &.content-right {
        align-items: flex-end;
    }

    .configured-link-content {
        width: 100%;
        height: 50px;
        background-color: $color-13;
        order: 2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        margin: 10px 0 0;
        padding: 0 22px;

        label {
            font-size: 2rem;
            font-weight: 700;
        }

        a {
            font-size: 0;
            order: 2;
        }
    }

    label {
        display: block;
        margin: 0;

        font-size: 12px;
        font-weight: 500;
        order: 1;
        transition-property: color;
        transition-duration: 0.2s;

        &.active {
            color: $color-13;
        }
    }

    input[type="text"],
    input[type="email"],
    select {
        width: 100%;
        height: 45px;
        color: $color-black;
        outline: none;
        border: none;
        border-bottom: 1px solid black;
        box-sizing: border-box;

        font-weight: 700;
        font-size: 2rem;

        transition-property: border;
        transition-duration: 0.2s;
        order: 2;

        &:focus,
        &:focus-within,
        &:active {
            border-color: $color-13;

            & ~ label {
                color: $color-13;
            }
        }
    }

    app-fs-dropdown-menu {
        order: 2;
        margin: 10px 0 0 0;
    }

    .app-list {
        padding: 20px;
        box-sizing: border-box;

        .app {
            margin: 0 0 10px;
            cursor: pointer;

            font-weight: bold;
            font-size: 1.6rem;
        }
    }

    .btn-send {
        @include fs-button-skew-right-from-bottom(2px solid transparent, $color-13, $color-black);

        font-weight: 700;
        font-size: 2rem;
        min-width: 170px;
        width: 290px;
        margin: 0 20px 0 0;

        &:disabled {
            opacity: 0.5;
            cursor: no-drop;
        }

        &:hover {
            @include fs-button-skew-right-from-bottom(2px solid transparent, $color-yellow-hover, $color-black);
        }
    }
}

.print-display {
    display: none !important;
}

body {
    section {
        .container {
            // padding: 60px 0 0 0;
        }
    }
}

@media print {
    * {
        -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
        color-adjust: exact !important; /*Firefox*/
    }

    app-pricing-talk,
    app-fs-faq,
    app-contact,
    app-footer {
        display: none !important;
    }

    app-fs-timeline {
        margin-top: 30px !important;
    }

    .print-display {
        display: block !important;
    }
}
