$color-grey: #d7d7d7;
$color-yellow: #fcea10;
$color-yellow-hover: #E9D811;
$color-purple-hover: #3D31FE;
$color-black-1: black;
$color-black: #212121;
$color-white: #ffffff;
$color-purple: #8571ea;
$color-orange: rgba(245, 113, 0, 1);
$color-blue: #14378b;
$color-light-blue: #4480f3;
$grid-dash-color: rgba(245, 113, 0, 0.6);
$color-1: #ff4361;
$color-2: #fbeab5;
$color-3: #f1a638;
$color-4: #f9ba31;
$color-5: #6eb785;
$color-6: #ffe7aa;
$color-7: #cadef4;
$color-8: #4f4f4f;
$color-9: rgba(0, 0, 0, 0.7);
$color-10: rgba(97, 167, 248, 0.12);
$color-11: #61a7f8;
$color-12: #ebf4fe;
$color-13: #fcea10;
$color-14: #f0f0f0;
$color-15: #272727;
$color-16: #c7c7c7;
$color-17: #e4e4e4;
$color-18: #cbcbcb;
$color-19: #d8d8d8;
$color-black-2: black;
$color-work-media: rgba(80, 49, 169, 0.8);
$color-work-media-gradient: linear-gradient(135deg, rgba(80, 49, 169, 0.8), rgba(41, 19, 105, 1));
