.fs-checkbox-container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 2.2rem;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
}

.fs-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: 2px solid #fcea10;
    box-sizing: border-box;

    &:after {
        content: "";
        position: absolute;
        display: none;
    }
}

.fs-checkbox-container input:checked ~ .fs-checkmark {
    background-color: #fcea10;
}

.fs-checkbox-container {
    input:checked ~ .fs-checkmark:after {
        display: block;
    }

    .fs-checkmark:after {
        left: 7px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid #000;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }
}
